import { generateId } from "@/utils/utils";
import {
  CreateDirectOrderResponseData,
  DIRECTS_ORDER_FIELDS,
  DirectOrderTrip,
  DirectOrderTripFieldTypes,
  PayloadDirectOrderPost,
  REQUIRED_FIELDS,
  RepeatTripConfig,
  StoreContext,
  OrderDetailsPayload,
  OrderDetailsResponseData,
  OrderFormType,
} from "./types";
import { RESPONSE_TYPE } from "@/services/enums/httpEnum";
import useLoaderStore from "../loader/loader.store.ts";
import {
  createDirectOrder,
  fetchOrderDetails,
} from "@/services/api/orders/directOrderApi.ts";
import { ContactPersonType } from "@/stores/order/types.ts";
import { ORDER_TYPE } from "@/services/enums/commonEnum.ts";

import { executeRepeatTrip } from "@/controllers/order/RepeatTripController.ts";
import { executeCopyOrder } from "@/controllers/order/CopyOrderController.ts";
import { Product } from "@/stores/product/type.ts";
import useUserStore from "../user/user.store.ts";
import { TViaPoints } from "@/components/ViaPointsPackage/types/types.ts";
import { createDirectOrderTrip } from "@/utils/order.utils.ts";

const generateRoutePoints = (product) => {
  const routePoints: TViaPoints[] = [];
  for (const data of product.route_points) {
    const newRoutePoint: TViaPoints = {
      id: data.id,
      point: data.point,
      point_latitude: data.point_latitude,
      point_longitude: data.point_longitude,
      point_formal: "", //expected from teq as empty string or point name.
      sequence: data.sequence,
      radius: data.radius,
      type: data.type,
      planned_arrival_time: product?.end_datetime || null,
      planned_departure_time: product.start_datetime || null,
      actual_departure_time: null,
      planned_distance: data.planned_distance,
      planned_duration: data.planned_duration,
      pause_duration: useUserStore().getSelectedCompanyInfo.default_pause_time,
    };

    routePoints.push(newRoutePoint);
  }
  return routePoints;
};

export default {
  /**
   * Adds a new trip to the `directOrder` object in the store.
   *
   * This method creates a new trip with default values and assigns it a unique ID.
   * The new trip is then added to the `directOrder` object in the store's state.
   * @param {StoreContext} this - The context of the store.
   * @param product
   */
  addTripToDirectOrder(this: StoreContext, product?: Product): void {
    const newTrip: DirectOrderTrip = {
      [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
      [DIRECTS_ORDER_FIELDS.TEMPLATE_ID]: product ? product.id : null,
      [DIRECTS_ORDER_FIELDS.PRODUCT_CODE]: product?.product_code ?? "",
      [DIRECTS_ORDER_FIELDS.ALT_TRIP_NAME]: product?.product_name ?? "",
      [DIRECTS_ORDER_FIELDS.ORDER_TYPE]: ORDER_TYPE.SINGLE,
      [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: product?.start_datetime ?? "",
      [DIRECTS_ORDER_FIELDS.TRAVEL_FROM]: product?.travel_from ?? "",
      [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: {
        oneway: product ? generateRoutePoints(product) : [],
        return: [],
      },
      [DIRECTS_ORDER_FIELDS.TRAVEL_TO]: product?.travel_to ?? "",
      [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: product?.end_datetime ?? "",
      [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME_RETURN]: "",
      [DIRECTS_ORDER_FIELDS.BUS_AVAILABILITY]: false,
      [DIRECTS_ORDER_FIELDS.DISTANCE]: {
        oneway: product?.distance ?? 0,
        return: 0,
      },
      [DIRECTS_ORDER_FIELDS.DURATION]: {
        oneway: product?.duration ?? 0,
        return: 0,
      },
      [DIRECTS_ORDER_FIELDS.FROM_RADIUS]: product?.from_radius ?? null,
      [DIRECTS_ORDER_FIELDS.TO_RADIUS]: product?.to_radius ?? null,
      [DIRECTS_ORDER_FIELDS.POC]: {} as ContactPersonType,
      [DIRECTS_ORDER_FIELDS.PAX]: product?.pax ?? 0,
      [DIRECTS_ORDER_FIELDS.INVOICE_REF]: "",
      [DIRECTS_ORDER_FIELDS.COMMENT_CUSTOMER]:
        product?.product_description ?? "",
      [DIRECTS_ORDER_FIELDS.WAITING_TIME]: 0,
      [DIRECTS_ORDER_FIELDS.PRICE]: product?.price_inc_vat ?? 0,
      [DIRECTS_ORDER_FIELDS.PRICE_EX_VAT]: product?.price_ex_vat ?? 0,
      [DIRECTS_ORDER_FIELDS.FILE_1]: product?.upload_file_1_name,
      [DIRECTS_ORDER_FIELDS.FILE_2]: product?.upload_file_2_name,
    };
    this.$state.directOrder.trip[newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]] =
      newTrip;
  },

  addExcelTripToDirectOrder(
    this: StoreContext,
    newTrip: DirectOrderTrip | null
  ): void {
    if (newTrip) {
      this.$state.directOrder.trip[newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]] =
        newTrip;
    } else {
      const newTrip: DirectOrderTrip = createDirectOrderTrip();
      this.$state.directOrder.trip[newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]] =
        newTrip;
    }
  },

  /**
   * Updates a specific field of a direct order trip in the local state.
   * @param tripId - The ID of the trip.
   * @param field - The field to update in the direct order trip.
   * @param value - The new value for the field.
   * @param hasError
   */
  updateLocalTripSpecific(
    this: StoreContext,
    tripId: string,
    field: keyof DirectOrderTrip,
    value: DirectOrderTripFieldTypes,
    hasError = false
  ): void {
    if (
      Object.values(REQUIRED_FIELDS).includes(
        field as unknown as REQUIRED_FIELDS
      )
    ) {
      this.updateOrPushErrorTripSpecific(tripId, field, hasError);
    }
    if (this.$state.directOrder.trip[tripId]) {
      const trip = this.$state.directOrder.trip[tripId];
      const updatedTrip = {
        ...trip,
        [field]: value,
      };
      this.$state.directOrder.trip[tripId] = updatedTrip;
    }
    if (!tripId.startsWith("FE-")) {
      if (!this.$state.directOrder.editedTripIds.includes(tripId))
        this.$state.directOrder.editedTripIds.push(tripId);
    }
  },

  /**
   * Updates or pushes an error state for a specific trip.
   *
   * This method manages error states for trip data by either updating an existing
   * error field for a given trip or pushing a new error state if the trip doesn't
   * already have one. If `flag` is set to `false`, it removes the error field.
   * @param {StoreContext} this - The context of the store.
   * @param {string} tripId - The ID of the trip to update or push an error state for.
   * @param {keyof DirectOrderTrip} field - The field in the trip to set or clear an error state.
   * @param {boolean} [flag] - The flag to set or clear the error state. Defaults to `true`.
   */
  updateOrPushErrorTripSpecific(
    this: StoreContext,
    tripId: string,
    field: keyof DirectOrderTrip,
    flag = true
  ): void {
    if (
      Object.values(REQUIRED_FIELDS).includes(
        field as unknown as REQUIRED_FIELDS
      )
    ) {
      const errors = this.$state.errors;

      if (errors[tripId]) {
        if (flag) {
          errors[tripId] = {
            ...errors[tripId],
            [field]: flag,
          };
          console.log("updatedTripError 1", errors[tripId]);
        } else {
          if (errors[tripId][field]) {
            delete errors[tripId][field];

            if (Object.keys(errors[tripId]).length === 0) {
              delete errors[tripId];
            }
          }
          console.log("updatedTripError 2", errors[tripId]);
        }
      } else if (flag) {
        errors[tripId] = { [field]: flag };
        console.log("updatedTripError 3", errors[tripId]);
      } else {
        console.log("tripId", tripId);
      }
    }
  },

  /**
   * Retrieves a specific field value from a trip in the `directOrder` object.
   *
   * This method fetches the value of a specified field for a given trip ID from
   * the `directOrder` object in the store's state.
   * @param {StoreContext} this - The context of the store.
   * @param {string} tripId - The ID of the trip to retrieve data from.
   * @param {string} fieldName - The name of the field to retrieve the value of.
   * @returns {DirectOrderTripFieldTypes} - The value of the specified field for the given trip.
   */
  getLocalTripSpecific(
    this: StoreContext,
    tripId: string,
    fieldName: string
  ): DirectOrderTripFieldTypes {
    const trip = this.$state.directOrder.trip[tripId];
    return trip ? trip[fieldName] : "";
  },

  /**
   * Checks if a specific field in a trip has an error state.
   *
   * This method verifies whether a specific field for a given trip ID has an
   * error state in the `errors` object within the store's state.
   * @param {StoreContext} this - The context of the store.
   * @param {string} tripId - The ID of the trip to check for errors.
   * @param {string} fieldName - The name of the field to check for an error state.
   * @returns {boolean} - Returns `true` if the field has an error, otherwise `false`.
   */
  getLocalTripErrorSpecific(
    this: StoreContext,
    tripId: string,
    fieldName: string
  ): boolean {
    const tripError = this.$state.errors[tripId];
    return tripError ? !!tripError[fieldName] : false;
  },

  /**
   * Removes a trip from the `directOrder` object in the store's state.
   *
   * This method deletes the trip with the specified `tripId` from the `directOrder`
   * object. It updates the state directly and logs a message indicating the outcome.
   * @param {StoreContext} this - the state
   * @param {string} tripId - The ID of the trip to remove.
   * @param tripIds
   * @param editMode
   */
  cancelTripFromDirectOrder(this: StoreContext, tripIds: string[]): void {
    const { directOrder } = this.$state;

    tripIds.forEach((tripId) => {
      // Check if the trip exists in directOrder
      if (directOrder.trip[tripId]) {
        const id = directOrder.trip[tripId][DIRECTS_ORDER_FIELDS.ID];

        if (id) {
          directOrder.cancel_trips = [...directOrder.cancel_trips, id];
          if (directOrder.editedTripIds.includes(tripId))
            directOrder.editedTripIds = directOrder.editedTripIds.filter(
              (id) => id !== tripId
            );
        } else {
          delete directOrder.trip[tripId];
          console.log(`Trip with ID ${tripId} has been removed.`);
        }

        this.removeErrorFromDirectOrder(tripId);
      } else {
        console.log(`Trip with ID ${tripId} does not exist.`);
      }
    });
  },

  /**
   * Removes a error from the `directOrder` object in the store's state.
   *
   * This method deletes the error with the specified `tripId` from the `directOrder`
   * object. It updates the state directly and logs a message indicating the outcome.
   * @param {StoreContext} this - the state
   * @param {string} tripId - The ID of the error to remove.
   */
  removeErrorFromDirectOrder(this: StoreContext, tripId: string): void {
    const { errors } = this.$state;

    // Check if the error exists in directOrder
    if (errors[tripId]) {
      delete errors[tripId];
      console.log(`Error with ID ${tripId} has been removed.`);
    } else {
      console.log(`Error with ID ${tripId} does not exist.`);
    }
  },

  /**
   * Copies a trip from the direct order store to a new trip with a generated ID.
   * If the trip with the specified ID does not exist, it logs an error message.
   * If the trip data fails validation, it adds the validation errors to the state and logs an error message.
   * Otherwise, it creates a new trip with a generated ID and adds it to the direct order store.
   * @param {string} tripId - The ID of the trip to be copied.
   * @returns {void}
   */
  copyTripFromDirectOrder(this: StoreContext, tripId: string): void {
    const { directOrder } = this.$state;
    const trip = directOrder.trip[tripId];
    if (!trip) {
      console.log(`Trip with ID ${tripId} not found.`);
      return;
    }

    // Validate the trip data
    const validationErrors = this.validateTripData(trip);

    if (Object.keys(validationErrors).length > 0) {
      this.$state.errors[tripId] = validationErrors;
      console.log(
        `Validation failed. Errors for trip: ${tripId}`,
        this.$state.errors[tripId]
      );
      // Optionally, display a message to the user or take other actions
      return;
    }
    if (trip) {
      const newTrip: DirectOrderTrip = {
        ...trip,
        [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
        [DIRECTS_ORDER_FIELDS.PRICE]: 0,
        [DIRECTS_ORDER_FIELDS.PRICE_EX_VAT]: 0,
      };

      // If the trip has an ID (coming from BE), is not bus available, and is a round trip, change the order type to single.
      if (
        newTrip[DIRECTS_ORDER_FIELDS.ID] &&
        newTrip[DIRECTS_ORDER_FIELDS.BUS_AVAILABILITY] === false &&
        newTrip[DIRECTS_ORDER_FIELDS.ORDER_TYPE] === ORDER_TYPE.ROUND
      ) {
        newTrip[DIRECTS_ORDER_FIELDS.ORDER_TYPE] = ORDER_TYPE.SINGLE;
      }

      // If the trip has an ID from BE, remove it as it is a new trip copy.
      if (newTrip[DIRECTS_ORDER_FIELDS.ID]) {
        delete newTrip[DIRECTS_ORDER_FIELDS.ID];
      }
      this.$state.directOrder.trip[newTrip.gen_tid] = newTrip;
    }
  },

  checkErrors(this: StoreContext, tripId: string): boolean {
    const { directOrder, errors } = this.$state;
    const trip = directOrder.trip[tripId];
    if (!trip) {
      console.log(`Trip with ID ${tripId} not found.`);
      return;
    }

    // Validate the trip data
    const validationErrors = this.validateTripData(trip);

    if (Object.keys(validationErrors).length > 0) {
      errors[tripId] = validationErrors;
      console.log("Validation failed. Errors for trip:", errors[tripId]);
      // Optionally, display a message to the user or take other actions
      return true;
    }
    return false;
  },

  /**
   * Copies a trip from the direct order store to a new trip with a generated ID.
   * If the trip with the specified ID does not exist, it logs an error message.
   * If the trip data fails validation, it adds the validation errors to the state and logs an error message.
   * Otherwise, it creates a new trip with a generated ID and adds it to the direct order store.
   * @param {string} tripId - The ID of the trip to be copied.
   * @param repeatConfig
   * @returns {void}
   */
  repeatTripFromDirectOrder(
    this: StoreContext,
    tripId: string,
    repeatConfig: RepeatTripConfig
  ): void {
    const { directOrder } = this.$state;
    const tempTrip = directOrder.trip[tripId];
    if (!tempTrip) {
      console.log(`Trip with ID ${tripId} not found.`);
      return;
    }

    if (tempTrip) {
      const trip: DirectOrderTrip = { ...tempTrip };
      // If the trip has an ID (coming from BE), is not bus available, and is a round trip, change the order type to single.
      if (
        trip[DIRECTS_ORDER_FIELDS.ID] &&
        trip[DIRECTS_ORDER_FIELDS.BUS_AVAILABILITY] === false &&
        trip[DIRECTS_ORDER_FIELDS.ORDER_TYPE] === ORDER_TYPE.ROUND
      ) {
        trip[DIRECTS_ORDER_FIELDS.ORDER_TYPE] = ORDER_TYPE.SINGLE;
      }
      const repeatedTrips = executeRepeatTrip(trip, repeatConfig);
      directOrder.trip = {
        ...directOrder.trip,
        ...repeatedTrips,
      };
    }
  },

  copyOrderFromDirectOrder(
    this: StoreContext,
    startDate: Date,
    trips: OrderFormType
  ): void {
    const orderData = {
      ...this.$state.directOrder,
      trip: trips,
    };
    const newOrder = executeCopyOrder(orderData, startDate);
    this.$state.directOrder = newOrder;
  },

  validateTripData(trip: DirectOrderTrip): Record<REQUIRED_FIELDS, boolean> {
    const errors = {} as Record<REQUIRED_FIELDS, boolean>;

    // Iterate over the required fields and check for missing values
    Object.values(REQUIRED_FIELDS).forEach((field) => {
      if (
        field === REQUIRED_FIELDS.ARRIVAL_DATETIME &&
        trip[DIRECTS_ORDER_FIELDS.ORDER_TYPE] === ORDER_TYPE.ROUND &&
        trip[DIRECTS_ORDER_FIELDS.BUS_AVAILABILITY] === true
      ) {
        return;
      }
      if (!trip[field]) {
        errors[field] = true;
      }
    });

    return errors;
  },

  /**
   * Posts a direct order to the backend service.
   * @param {StoreContext} this - The Vuex store context.
   * @param {PayloadDirectOrderPost} payloadDirectOrderPost - The payload for posting a direct order.
   * @param {string} company - The company identifier for which the direct order is to be created.
   * @returns {Promise<object>} - A promise that resolves to the response object from the API call.
   * @throws {Error} - Throws an error if the API response status is not successful or if any error occurs during the API call.
   */
  async postDirectOrder(
    this: StoreContext,
    payloadDirectOrderPost: PayloadDirectOrderPost,
    company: string
  ): Promise<CreateDirectOrderResponseData> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return createDirectOrder(payloadDirectOrderPost, company)
      .then((res) => {
        loader.changeLoadingStatus({ isLoading: false });
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");

        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },

  async getOrderDetails(
    this: StoreContext,
    payload: OrderDetailsPayload,
    isCopyOrder = false
  ): Promise<OrderDetailsResponseData> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    return fetchOrderDetails(payload)
      .then((res) => {
        const response = res.data;
        if (res.status == 200 && response?.status !== RESPONSE_TYPE.SUCCESS)
          throw Error("API error");
        if (!isCopyOrder) {
          this.$state.directOrder.trip = response.data.trips;
        }
        this.$state.directOrder.contact_person = response.data.contact_person;
        this.$state.directOrder.org_id = response.data.org_id;
        this.$state.directOrder.order_id = response.data.order_id;
        this.$state.directOrder.gen_oid = response.data.gen_oid;

        loader.changeLoadingStatus({ isLoading: false });
        return response.data;
      })
      .catch((error) => {
        loader.changeLoadingStatus({ isLoading: false });
        throw error;
      });
  },
};
