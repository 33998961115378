import {
  ProductListResponseData,
  ProductTagPayload,
  ProductTagResponseData,
} from "@/stores/product/type.ts";
import {
  fetchProductListApi,
  fetchProductTagsApi,
} from "@/services/api/product/productApi.ts";
import useLoaderStore from "../loader/loader.store.ts";
import { QueryFilter } from "../../../types/common";

export default {
  async fetchProducts(
    payload: QueryFilter,
    virtualScroll = false
  ): Promise<ProductListResponseData> {
    const loader = useLoaderStore();
    loader.changeLoadingStatus({ isLoading: true });
    try {
      const { data: productsResponse } = await fetchProductListApi(payload);
      if (virtualScroll === false) {
        this.$state.products = productsResponse.data.results;
      } else {
        if (this.$state.products.length !== productsResponse.data.count) {
          this.$state.products = [
            ...this.$state.products,
            ...productsResponse.data.results,
          ];
        }
      }
      this.$state.count = productsResponse.data.count;
      return productsResponse.data;
    } catch (error) {
      console.log(error);
    } finally {
      loader.changeLoadingStatus({ isLoading: false });
    }
  },

  async fetchProductTags(
    payload: ProductTagPayload
  ): Promise<ProductTagResponseData> {
    try {
      const { data: productTagsResponse } = await fetchProductTagsApi(payload);
      this.$state.productTags = productTagsResponse.data.tags;
      return productTagsResponse.data;
    } catch (error) {
      console.log(error);
    }
  },
};
